<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.idfuerza_seguridad"
          class="mr-1"
          small
          label
          dark
          color="red"
        >
          {{ formattedItem.fuerza_seguridad_descripcion }}
        </v-chip>
        <v-chip
          v-if="formattedItem.informe_cliente"
          class="mr-1"
          small
          label
          dark
          color="red"
        >
          Cliente
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="formattedItem.cantidad_adjuntos > 0"
    >
      <v-icon>{{ $vuetify.icons.values.attach }}</v-icon>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { LPARTE_NOVEDAD } from '@/utils/consts'
import { isSameDay } from '@/utils/date'


export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      if (isSameDay(item.fnovedad, item.fparte)) {
        item.title = `${this.$options.filters.shortTime(item.fnovedad)} - ${item.tnovedad_descripcion}`
      } else {
        item.title = `${this.$options.filters.shortDateTime(item.fnovedad)} - ${item.tnovedad_descripcion}`
      }
      item.subtitle = item.descripcion
      if (item.estado === LPARTE_NOVEDAD.estados.pendiente) {
        item.avatar = 'P'
        item.avatarColor = LPARTE_NOVEDAD.colores.pendiente
      } else {
        item.avatar = 'F'
        item.avatarColor = LPARTE_NOVEDAD.colores.finalizado
      }
      return item
    },
  },
}
</script>
