import { toDate, endOfDay } from '@/utils/date'
import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLparteNovedad (Vue, filter, search, sorter, page, idparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idparte_novedad', idparteNovedad)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    const resp = await Vue.$api.call('lparteNovedad.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectLparteNovedadRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idlparte_novedad', pks)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectParteNovedad (Vue, idparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_novedad', idparteNovedad)
    const resp = await Vue.$api.call('parteNovedad.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
